


































import { defineComponent } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(_, { root }) {
    const getFile = (name: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`file/${name}`, { responseType: "blob" })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${name}`);
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        })
        .catch((e) => console.log(e));
    };
    return { getFile };
  },
});
